<template>
  <v-app>
    <!-- LOADING -->
    <!-- <loading></loading> -->
    <snackBar></snackBar>
    <appToolbar @setNav="setNav()"></appToolbar>
    <appNav></appNav>
    <v-content>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-content>
    <appFooter></appFooter>
  </v-app>
</template>

<script>
import appFooter  from '@/components/appComponents/footer'
import appNav     from '@/components/appComponents/nav'
import appToolbar from '@/components/appComponents/toolbar'
import loading    from '@/components/appComponents/loading'
import snackBar   from '@/components/appComponents/snackBar'

export default {
  components: {
    appFooter, 
    appNav,
    appToolbar,
    loading,
    snackBar
  },
  data(){
    return {

}
  },
  methods: {
    setNav(){
      this.$store.dispatch('setNavState')
    }
  },
  computed: {
    getNavState(){
      return this.$store.getters.getNavState
    }
  }
}
</script>

<style>

</style>
