<template>
    <v-footer app tile color="secondary" dark class="pa-3">
        <v-spacer></v-spacer>
        <div class="subtitle-1">Currently serving {{getThemeTotal}} different quirky themes to choose from!</div>
        <v-spacer></v-spacer>
        <div>&copy; {{ new Date().getFullYear() }} - DotaTheme.com</div>
    </v-footer>
</template>

<script>
export default {
    computed: {
        getThemeTotal(){
           return this.$store.getters.getThemes.filter(theme => theme.approved == "true").length
        }
    }
}
</script>