export default {
    getAdminNav(state){
        return state.adminNav
    },
    getHeroes(state){
        return state.heroes
    },
    getIssues(state){
        return state.issues
    },
    getLoading(state){
        return state.loading
    },
    getNav(state){
        return state.nav
    },
    getNavState(state){
        return state.navState
    },
    getNotes(state){
        return state.notes
    },
    getSnackBar(state){
        return state.snackBar
    },
    getThemes(state){
        return state.themes
    },
    getUser(state){
        return state.user
    },
    getUserList(state){
        return state.userList
    }
}