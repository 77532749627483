<template>
    <!-- NAVIGATION DRAWER -->
    <v-navigation-drawer
      app
      clipped
      :value="getNavState"
    >
      <v-list>
        <v-list-item v-for="item in navList" :key="item.title" router :to="item.link">
          <v-list-item-icon>
            <v-icon left color="secondary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="secondary" to="/admin" v-if="getUser.status == true">
            <v-list-item-icon>
                <v-icon left color="white">fas fa-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="white--text">Admin Toolbox</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logInOut()">
            <v-list-item-icon>
                <v-icon left color="secondary">{{getUser.user ? 'fas fa-sign-out-alt' : 'fas fa-sign-in-alt'}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{getUser.user ? 'Logout' : 'Login'}}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
  methods: {
    logInOut(){
      if (this.getUser.user == ''){
        this.$router.push('/login')
      }
      else {
        this.$store.dispatch('logout')
      }
    }
  },
  computed: {
    getNavState(){
      return this.$store.getters.getNavState
    },
    getUser(){
        return this.$store.getters.getUser
    },
    navList () {
      return this.$store.getters.getNav
    }
  },
  name: 'App'
}
</script>