import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'

// FIREBASE
import * as firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import firebaseConfig from '@/plugins/firebase.js'
firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created(){
    store.dispatch('getHeroes')
    store.dispatch('getIssues')
    store.dispatch('getNotes')
    store.dispatch('getThemes')
  },
  render: h => h(App)
}).$mount('#app')
