<template>
    <v-app-bar app dark flat elevate-on-scroll clipped-left color="secondary">
        <v-app-bar-nav-icon @click="$emit('setNav')"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>
            <router-link to="/" tag="span" style="cursor: pointer" class="title">
                <img src="@/assets/logo-white.png" height="15" width="15">
                DotaTheme.com
            </router-link>
        </v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
    
}
</script>