<template>
    <v-snackbar
      top
      v-model="getSnackBar.status"
      :multi-line="true"
    >
      {{ getSnackBar.msg }}
    </v-snackbar>
</template>
<script>
export default {
    computed: {
        getSnackBar(){
            return this.$store.getters.getSnackBar
        }
    }
}
</script>