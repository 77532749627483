import store  from '@/store/index.js'
import router from '@/router/index.js'
export default (to, from, next) => {
    // FIRST CHECK IF THERE IS A USER
  if (store.getters.getUser.user != '') {
    // ONLY ALLOW THE USER TO ACCESS ADMIN AREAS IF MARKED AS 'ACTIVE'
    if (store.getters.getUser.status){
      next()
    }
    else {
      next('/disabled')
    }
  }
  else {
    next('/login')
  }
}