import Vue from 'vue'
import Vuex from 'vuex'

// VUEX PIECES
import actions    from './actions.js'
import getters    from './getters.js'
import mutations  from './mutations.js'
import state      from './state.js'

Vue.use(Vuex)

export default new Vuex.Store({
  actions   : actions,
  getters   : getters,
  mutations : mutations,
  state     : state
})
