
export default {
    adminNav: [
        {
            title: "Users",
            icon: "fas fa-users-cog",
            link: "/users"
        },
        {
            title: "Issues",
            icon: "fas fa-exclamation-triangle",
            link: "/issues"
        },
        {
            title: "Notes",
            icon: "fab fa-wpforms",
            link: "/notes"
        },
        {
            title: "Pending Themes",
            icon: "fas fa-hourglass-start",
            link: "/pending"
        },
        {
            title: "Manage Heroes",
            icon: "fas fa-user-astronaut",
            link: "/heroes"
        }
    ],
    heroes: [],
    issues: [],
    loading: false,
    nav: [
        {
            title: "Home",
            icon: "fas fa-home",
            link: "/"
        },
        {
            title: "Create Theme",
            icon: "fas fa-plus-circle",
            link: "/create"
        },
        // {
        //     title: "Drafting Screen",
        //     icon: "fas fa-list",
        //     link: "/draft"
        // },
        {
            title: "Random Theme",
            icon: "fas fa-random",
            link: "/random"
        },
        {
            title: "Advanced Search",
            icon: "fas fa-search-plus",
            link: "/search"
        },
        {
            title: "About",
            icon: "fas fa-info-circle",
            link: "/about"
        }
    ],
    navState: true,
    notes: [],
    snackBar: {
        status  : false,
        msg     : ""
    },
    themes: [],
    user: {
        id: "",
        status: "",
        user:   ""
    },
    userList: []
}