export default {
    setHeroes(state, payload){
        state.heroes = payload
    },
    setIssues(state, payload){
        state.issues = payload
    },
    setLoading(state, payload){
        state.loading = payload
    },
    setNavState(state){
        state.navState = !state.navState
    },
    setNotes(state, payload){
        state.notes   = payload
    },
    setSnackBar(state, payload){
        state.snackBar  = payload
    },
    setThemes(state, payload){
        state.themes = payload
    },
    setUser(state, payload){
        state.user  = payload
    },
    setUserList(state, payload){
        state.userList = payload
    }
}