import * as firebase from "firebase/app"
import "firebase/firestore"
import router from '@/router/index.js'

export default {
  changeIssueStatus({commit, dispatch}, payload){
    firebase.firestore().collection("Issues").doc(payload.id).update(payload)
    .then(() => {
      commit('setSnackBar', {status: true, msg: `This issue has been set to ${payload.active ? "open" : "closed"}.`})
      dispatch('getIssues')
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  changeUserStatus({commit, dispatch}, payload){
    firebase.firestore().collection('Users').doc(payload.id).update(payload)
    .then(() => {
      commit('setSnackBar', {status: true, msg: `Status has been changed for ${payload.user}`})
      dispatch('getUserList')
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  createHero({commit, dispatch}, payload){
    commit('setLoading', true)
    firebase.firestore().collection("Heroes").doc().set(payload)
    .then(() => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `The hero ${payload.name} has been created.`})
      router.push('/admin')
      dispatch('getHeroes')
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  createIssue({commit, dispatch}, payload){
    commit('setLoading', true)
    firebase.firestore().collection("Issues").doc().set(payload)
    .then(() => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `Your issue for ${payload.theme} has been submitted`})
      router.push('/')
      dispatch('getIssues')
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  createNote({commit, dispatch}, payload){
    commit('setLoading', true)
    firebase.firestore().collection("Notes").doc().set(payload)
    .then(() => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `Your note has been created.`})
      dispatch('getNotes')
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  createUser({commit, dispatch}, payload){
    commit('setLoading', true)
    firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
    .then(() => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `User ${payload.email} has been created.`})
      dispatch('getUserList')
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: err})
      commit('setLoading', false)
    })
  },
  createTheme({commit, dispatch}, payload){
    commit('setLoading', true)
    let newTheme = {
      approved    : payload.themeApproved,
      description : payload.themeDesc ? payload.themeDesc : "",
      heroes      : payload.selectedHeroes,
      name        : payload.themeName,
      notes       : payload.themeNotes ? payload.themeNotes : ""
    }
    firebase.firestore().collection("Themes").doc().set(newTheme)
    .then(() => {
      commit('setSnackBar', {status: true, msg: 'Your theme has been submitted. Thank you.'})
      commit('setLoading', false)
      dispatch('getThemes')
      router.push('/')
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `I'm sorry something went wrong. Here is the error: ${err}`})
    })
  },
  deleteIssue({commit, dispatch}, payload){
    firebase.firestore().collection("Issues").doc(payload.id).delete()
    .then(() => {
      commit('setSnackBar', {status: true, msg: `This issue has been deleted.`})
      dispatch('getIssues')
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  getHeroes({commit}){
    commit('setLoading', true)
    firebase.firestore().collection('Heroes').get()
    .then(allHeroes => {
        let heroList = []
        allHeroes.forEach(hero => {
          let heroData  = hero.data()
          heroData.id   = hero.id
          heroList.push(heroData)
        })
        commit('setLoading', false)
        commit('setHeroes', heroList)
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `I was unable to get the list of heroes. Here is the error: ${err}`})
    })
  },
  getIssues({commit}){
    commit('setLoading', true)
    firebase.firestore().collection('Issues').get()
    .then(allIssues => {
      let issueList = []
      allIssues.forEach(issue => {
        let issueData = issue.data()
        issueData.id  = issue.id
        issueList.push(issueData)
      })
      commit('setLoading', false)
      commit('setIssues', issueList)
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `I was unable to get the list of issues. Here is the error: ${err}`})
    })
  },
  getNotes({commit}){
    commit('setLoading', true)
    firebase.firestore().collection('Notes').get()
    .then(allNotes => {
        let noteList = []
        allNotes.forEach(note => {
            noteList.push(note.data())
        })
        commit('setLoading', false)
        commit('setNotes', noteList)
    })
    .catch(err => {
      commit('setLoading', false)
        console.log("Error", err)
    })
  },
  getThemes({commit}){
    commit('setLoading', true)
    firebase.firestore().collection('Themes').get()
    .then(allThemes => {
        let themeList = []
        allThemes.forEach(theme => {
          let thisTheme = theme.data()
          thisTheme.id  = theme.id
          themeList.push(thisTheme)
        })
        commit('setLoading', false)
        commit('setThemes', themeList)
    })
    .catch(err => {
      commit('setLoading', false)
      commit('setSnackBar', {status: true, msg: `I'm having trouble getting the themes right now. Please try again later.`})
    })
  },
  getUserList({commit}){
    firebase.firestore().collection('Users').get()
    .then(allUsers => {
      let userList = []
      allUsers.forEach(user => {
        let userData  = user.data()
        userData.id   = user.id
        userList.push(userData)
      })
      commit('setUserList', userList)
    })
  },
  setLoading({commit}, payload){
    commit('setLoading', payload)
  },
  login({commit, state}, payload){
    commit('setLoading', true)
    firebase.auth().signInWithEmailAndPassword(payload.username, payload.password)
    .then(res => {
      let userList = []
      firebase.firestore().collection('Users').get()
      .then(allUsers => {
        allUsers.forEach(user => {
          let userData  = user.data()
          userData.id   = user.id
          userList.push(userData)
        })
        commit('setUserList', userList)
        let loggedInUser = userList.find(user => user.user == res.user.email)
        commit('setUser', loggedInUser)
        router.push('/admin')
        commit('setSnackBar', {status: true, msg: `You have been successfully logged in.`})
        commit('setLoading', false)
      })
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `I was not able to log you in for some reason. Here is the error: ${err}`})
      commit('setLoading', false)
    })
  },
  logout({commit}){
    firebase.auth().signOut()
    .then(() => {
      let blankUser = {
          id: "",
          status: "",
          user:   ""
      }
      commit('setUser', blankUser)
      router.push('/')
      commit('setSnackBar', {status: true, msg: `You have been logged out.`})
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `I'm sorry something went wrong: ${err}`})
    })
  },
  resetPass({commit}, payload){
    commit('setLoading', true)
    firebase.auth().sendPasswordResetEmail(payload)
    .then(() => {
      commit('setSnackBar', {status: true, msg: `${payload} has been sent an email to reset his/her password.`})
      commit('setLoading', false)
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: err})
      commit('setLoading', false)
    })
  },
  sendPasswordReset({commit}, payload){
    firebase.auth().sendPasswordResetEmail(payload.user)
    .then(() => {
      commit('setSnackBar', {status: true, msg: `A password reset email has been sent to ${payload.user}`})
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
  setNavState({commit}){
    commit('setLoading', true)
    commit('setNavState')
  },
  updateHero({commit, dispatch}, payload){
    commit('setLoading', true)
    firebase.firestore().collection('Heroes').doc(payload.id).update(payload)
    .then(() => {
      commit('setSnackBar', {status: true, msg: `Hero ${payload.name} has been updated.`})
      commit('setLoading', false)
      dispatch('getHeroes')
      router.push('/admin')
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: err})
      commit('setLoading', false)
    })
  },
  updateTheme({commit, dispatch}, payload){
    firebase.firestore().collection('Themes').doc(payload.id).update(payload)
    .then(() => {
      commit('setSnackBar', {status: true, msg: `${payload.name} has been updated.`})
      commit('setLoading', false)
      dispatch('getThemes')
    })
    .catch(err => {
      commit('setSnackBar', {status: true, msg: `Something went wrong: ${err}`})
    })
  },
}
