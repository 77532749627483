import Vue          from 'vue'
import VueRouter    from 'vue-router'
import authguard    from '@/router/authguard.js'
import randomTheme  from '@/router/randTheme.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'about',
    component: () => import('../components/about.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../components/admin.vue'),
    beforeEnter: authguard
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('../components/create.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../components/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login.vue')
  },
  {
    path: '/random',
    name: 'random',
    component: () => import('../components/theme.vue'),
    beforeEnter: randomTheme
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../components/search.vue')
  },
  {
    path: '/theme/:id',
    name: 'theme',
    component: () => import('../components/theme.vue'),
    props: true
  },
  {
    path: '/wheel',
    name: 'wheel',
    component: () => import('../components/wheel.vue')
  },
  // ADMIN COMPONENTS
  {
    path: '/disabled',
    name: 'disabled',
    component: () => import('../components/adminComponents/disabled.vue')
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: () => import('../components/adminComponents/edit.vue'),
    props: true,
    beforeEnter: authguard
  },
  {
    path: '/issues',
    name: 'issues',
    component: () => import('../components/adminComponents/issues.vue'),
    beforeEnter: authguard
  },
  {
    path: '/heroes',
    name: 'heroes',
    component: () => import('../components/adminComponents/heroes.vue'),
    beforeEnter: authguard
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('../components/adminComponents/notes.vue'),
    beforeEnter: authguard
  },
  {
    path: '/pending',
    name: 'pending',
    component: () => import('../components/adminComponents/pending.vue'),
    beforeEnter: authguard
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../components/adminComponents/users.vue'),
    beforeEnter: authguard
  },
  {
    path: '*',
    component: () => import('../components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
