<template>
    <v-dialog v-model="getLoading" width="300" height="100">
        <v-card width="300" height="100" flat color="secondary" dark>
            <v-container fill-height>
                <v-row>
                    <v-col cols="12" class="text-center title">
                        Loading...
                        <br>
                        <v-progress-linear indeterminate></v-progress-linear>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    computed: {
        getLoading(){
            return this.$store.getters.getLoading
        }
    }
}
</script>