import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary   : '#b9cdda',
        secondary : '#6b7d7d',
        accent    : '#a6d8d4',
        info      : '#d7dae5',
        success   : '#8eaf9d'      
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
});

// https://coolors.co/d7dae5-b9cdda-a6d8d4-8eaf9d-6b7d7d